@import '../../../styles/variables.scss';

#VirtualExperience {
    width: 100%;
    margin: 0 auto;
    background-color: black;

    .main {
        margin-top: 70px;
        background-color: black;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        color: white;
        text-align: center;
        padding: 0 5%;

        p {
            margin-bottom: 70px;
            color: white;
        }

        img {
            width: 200px;
            margin-top: 70px;
        }

        h1 {
            color: white;
            margin-top: 0px;
        }
    }
        
    .sub-main {
        background-color: black;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        color: white;
        text-align: center;
        padding: 0 5% 70px 5%;

        p {
            margin-bottom: 10px;
            color: white;
        }

        h1 {
            color: white;
            margin-top: 0px;
        }
    }

    .pic {
        position: relative;
        margin-bottom: -5px;
        img {
            width: 100%;
        }
        p {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            color: white;
            text-shadow:  1px 0 0 #000;
            font-size: 40px;
            font-weight: 900;
            text-align: center;
            line-height: 40px;
        }
    }

    .whiteText {
        color: white;
        text-align: center;
    }

    .gradient {
        margin-top: 70px;
        color: white;
        text-align: center;
        margin-bottom: 10px;

        background: -webkit-linear-gradient(45deg, #7b0eaa,  #fa9ef6,  #7b0eaa);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }

    .pinkText {
        color: #fa9ef6;
        text-align: center;
    }

    .marginUp {
        margin-top: 70px;
    }

    .marginDown {
        margin-bottom: 70px;
    }

    .marginDown2 {
        margin-bottom: 20px;
    }

    .padding1 {
        padding: 0 5%;
    }

    .virtualText {
        margin-top: 100px;
    }

    .boxWrap {
        display: flex;
        justify-content: center;
        color: white;
        text-align: center;
        padding: 0 5%;
        .box {
            width: 50%;
            margin-right: 20px;
            margin-bottom: 90px;
            h3 {
                margin-bottom: 0px;
            }
            p {
                color: white;
                margin-bottom: 20px;
            }
            .sub {
                color: white;
            }
            &:last-child {
                margin-right: 0;
            }
        }

        & {
            @media (max-width: #{$mobile-width}) {
                flex-direction: column;
                .box {
                    width: 100%;
                    margin-right: 0;
                    margin-bottom: 40px;
                }
            }
            @media (max-width: #{$tablet-width}) {
                
            }
        }
    }

    .pinkText1 {
        color: $pink1;
    }

    .pinkText2 {
        color: $pink2;
    }

    .pinkText3 {
        color: $pink3;
    }

    .pinkText4 {
        color: $pink4;
    }

    .pinkText5 {
        color: $pink5;
    }

    .textGradientBox {
        padding: 0 5%;
        .gradient {
            margin-bottom: 50px;
        }
        p {
            color: white;
            text-align: center;
            margin-bottom: 30px;
        }
    }

    .textButton {
        display: flex;
        justify-content: space-between;
        width: 70%;
        margin-top: 70px;
        margin-bottom: 70px;
        h2 {
            color: white;
        }

        & {
            @media (max-width: #{$mobile-width}) {
                width: 100%;
                padding: 0 5%;
                flex-direction: column;
                h2 {
                    text-align: center;
                }
            }
            @media (max-width: #{$tablet-width}) {
                
            }
        }
    }

    .stats {
        display: flex;
        padding: 0 5%;
        .stat {
            text-align: center;
            padding: 10px;
            p {
                color: white;
            }
            h2 {
                margin-top: 10px;
            }
        }

        & {
            @media (max-width: #{$mobile-width}) {
                flex-direction: column;
            }
            @media (max-width: #{$tablet-width}) {
                
            }
        }
    }

    .logos {
        padding: 0px 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 70px;
        margin-bottom: 110px;

        .logo {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 12%;
            padding: 0 10px;

            img {
                max-width: 100%;
                max-height: 100%;
            }
        }
    }

    .bottomLinks {
        text-align: center;
        span {
            color: white;
            cursor: pointer;
        }
    }

    .contact {
        background-color: black;
        color: white;
        text-align: center;
        padding: 70px 5%;

        h4 {
            margin-top: 0;
            margin-bottom: 40px;
            color: white;
            font-weight: 300;
            font-size: 20px;

        }

        p {
            color: white;
            .linkEmail {
                color: $blue1;
            }
        }
    }
    
}