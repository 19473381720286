//  colors

$green1: #00b050;
$blue1: #0068ff;
$blue1light: #5697f9;
$blue1_hover: #035fea;
$pink1: #d60093;
$red1: #fa3455;
$orange1: #ff9f00;
$yellow1: #f5ef00;
$backgroundGray: #fbfbfb;
$backgroundGrayDarker: #f5f5f5;
$backgroundGrayVeryDark: #d4d3d3;
$backgroundGrayOutsourcing: #242426;

$pink1: #e88ffd;
$pink2: #bf50f0;
$pink3: #ad32d7;
$pink4: #911bc3;
$pink5: #7627cb;

// sizes

$mobile-width: 768px;
$tablet-width: 1024px;
