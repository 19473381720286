@import '../../../styles/variables.scss';

#Outsourcing {
    width: 100%;
    margin: 0 auto;
    background-color: $backgroundGrayOutsourcing;

    .main {
        margin-bottom: 70px;
        margin-top: 70px;
        background-color: black;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        color: white;
        text-align: center;
        padding: 0 5%;

        p {
            margin-bottom: 70px;
            color: white;
        }

        h1 {
            margin-top: 70px;
            color: white;
        }
    }
        
    .sub-main {
        margin-bottom: 70px;
        background-color: $backgroundGrayOutsourcing;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        color: white;
        text-align: center;
        padding: 0 5%;

        p {
            color: white;
        }

        h2 {
            margin-top: 0;
            color: white;
        }
    }

    .why-us {
        margin-bottom: 70px;
        h2 {
            color: white;
            margin-left: 40px;
            margin-top: 0;
        }

        .services-wrapper {
            background-color: black;
            background-image: url("../../../assets/pics/Bg_02.jpg");
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            padding: 40px 20px;

            .services {
                display: flex;
                
                .service {
                    padding: 20px 20px;
                    width: 20%;
                    .title {
                        color: white;
                    }
                    .text {
                        color: white;
                    }
                }
            }

            .sub-title {
                margin-top: 40px;
            }
        }

        & {
            @media (max-width: #{$mobile-width}) {
                h2 {
                    text-align: center;
                    margin-left: 0;
                }
                .services-wrapper {
                    background-image: none;
                    .services {
                        flex-direction: column;
                        text-align: center;
                        
                        .service {
                            width: 90%;
                        }
                    }
                }
            }
            @media (max-width: #{$tablet-width}) {
                
            }
        }
    }

    .bottomTextBtn {
        margin-top: 60px;
        display: flex;
        justify-content: space-between;

        .moveLeftBtn {
            margin-right: 40px;
        }
    }

    .ending {
        background-color: $backgroundGrayOutsourcing;
        color: white;
        text-align: center;
        padding: 0 5% 70px 5%;

        p {
            color: white;
        }
    }

    .contact {
        background-color: black;
        color: white;
        text-align: center;
        padding: 70px 5%;

        h2 {
            margin-top: 0;
        }

        p {
            color: white;
        }
    }
}