@import '../../../styles/variables.scss';

#Solutions {
    width: 100%;
    margin: 0 auto;

    .main {
        width: 96%;
        display: flex;
        margin-bottom: 70px;
        background-color: $backgroundGrayDarker;

        .left {
            width: 60%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            margin-right: 30px;
            margin-left: 24px;
            margin-top: 24px;
            margin-bottom: 24px;

            .checkmarks {
                .highlighted {
                    display: flex;
                    margin-left: 20px;
                    .checks {
                        margin-left: 20px;
                        margin-right: 20px;
                    }
                }
            }
            
            p {
                font-size: 20px;
                line-height: 20px;
                margin-bottom: 20px;
            }
        }

        .right {
            width: 40%;
            margin-right: 24px;
            margin-top: 24px;
            margin-bottom: 24px;
            display: flex;
            align-items: center;

            img {
                width: 100%;
                height: auto;
            }
        }

        & {
            @media (max-width: #{$mobile-width}) {
                background-color: white;
                display: block;
                text-align: center;
                margin-bottom: 40px;
                .left {
                    margin-right: 0;
                    width: 100%;
                    margin-left: 0;

                    .checkmarks {
                        .highlighted {
                            .checks {
                                margin-left: 0;
                                margin-right: 0;
                                text-align: center;
                            }
                        }
                    }
                }
                .right {
                    width: 100%;
                    margin-right: 0;
                    margin-top: 24px;
                    margin-bottom: 0;
                }
                #PrimaryButton.moveLeft {
                    justify-content: center;
                }
            }
            @media (max-width: #{$tablet-width}) {
                .left {
                    h2 {
                        margin-top: 0;
                    }
                }
            }
        }
    }

    .configurator {
        width: 100%;
        .configuratorTextBox {
            background-color: $backgroundGrayDarker;
            text-align: center;
            padding: 70px 5%;

            h2 {
                margin-top: 0;
                margin-bottom: 16px;
            }
            p {
                margin-bottom: 10px;

                &:last-child {
                    margin: 0;
                }
            }

            &:last-child {
                margin-bottom: 70px;
            }
        }
    }

    .increaseSales {
        width: 96%;
        .textBox {
            background-color: $backgroundGray;
            text-align: center;
            padding: 70px 5%;

            h2 {
                margin-top: 0;
                margin-bottom: 16px;
            }
            p {
                margin-bottom: 10px;

                &:last-child {
                    margin: 0;
                }
            }

            &:last-child {
                margin-bottom: 70px;
            }
        }
        img {
            width: 100%;
        }
    }

    .banner {
        width: 100%;
        background-color: black;
        text-align: center;
        padding: 5% 0;
        margin-bottom: 70px;
        margin-top: 70px;

        h1 {
            margin: 0;
            color: white;
            padding: 0 5%;
        }
    }

    .bannerImg {
        width: 100%;
        background-color: black;
        text-align: center;
        padding: 5% 0;
        margin-bottom: 70px;
        margin-top: 70px;
        position: relative;

        img {
            position: absolute;
            right: 0;
            top: 0;
            bottom: 0;
            height: 100%;
        }

        h1 {
            margin: 0;
            color: white;
            padding: 0 5%;
            position: relative;
        }

        p {
            margin-top: 10px;
            color: white;
            position: relative;
            z-index: 1;
        }
    }

    .twoBoxes {
        width: 96%;
        display: flex;
        margin-bottom: 70px;

        .left {
            background-color: $backgroundGray;
            margin-right: 24px;
            padding: 40px;
        }

        .right {
            background-color: $backgroundGray;
            padding: 40px;

            h2, p {
                text-align: right;
            }

            .checkmarks {
                margin-bottom: 20px;
                p {
                    text-align: left;
                }
                .line {
                    span {
                        margin-left: 10px;
                    }
                }
            }
        }

        & {
            @media (max-width: #{$mobile-width}) {
                flex-direction: column;
                .left {
                    margin-right: 0;
                    margin-bottom: 24px;
                }
            }
        }
    }

    .bannerImgWhite {
        width: 100%;
        background-color: $backgroundGrayDarker;
        text-align: center;
        padding: 5% 0;
        margin-bottom: 70px;
        margin-top: 70px;
        position: relative;

        img {
            position: absolute;
            right: 20px;
            bottom: 0;
            height: 90%;
        }

        h1 {
            margin: 0;
            padding: 0 5%;
            position: relative;
        }

        p {
            margin-top: 10px;
            position: relative;
            z-index: 1;
        }

        & {
            @media (max-width: #{$mobile-width}) {
                img {
                    display: none;
                }
            }
        }
    }

    .textWithBtn {
        width: 86%;
        margin-top: -4px;
        display: flex;
        text-align: left;
        align-items: center;
        padding: 5% 5%;
        background-color: $backgroundGrayDarker;
        margin-bottom: 70px;
        .left {
            width: 70%;
        }
        .right {
            width: 30%;
        }
        .aaa {
            color: white;
        }

        & {
            @media (max-width: #{$mobile-width}) {
                flex-direction: column;
                .left {
                    width: 100%;
                    text-align: center;
                }
                .right {
                    width: 100%;
                }
            }
        }
    }
    
    .prices {
        width: 96%;
        text-align: center;
        margin-bottom: 70px;
        background-color: $backgroundGray;
        .priceText {
            display: flex;
            text-align: left;
            align-items: center;
            padding: 5% 5%;
            .left {
                width: 70%;
            }
            .right {
                width: 30%;
            }
            .aaa {
                color: white;
            }
        }
        h1 {
            margin-top: 40px;
        }
        a {
            color: $blue1;
        }
        .pricesBoxes {
            display: flex;
            text-align: center;
            padding: 5%;
            img {
                width: 36px;
                margin-bottom: 10px;
            }
            .priceBox {
                padding: 24px;
                background-color: white;
                margin-right: 24px;
                flex: 0.33;
                .titlePrice {
                    font-size: 26px;
                    font-weight: 800;
                    margin-bottom: 16px;
                }

                &:last-child {
                    margin-right: 0;
                }
            }

            .blued {
                color: $blue1;
            }
        }

        & {
            @media (max-width: #{$mobile-width}) {
                text-align: center;
                .pricesBoxes {
                    display: block;
                    text-align: center;
                    .priceBox {
                        // flex: 0.5;
                        margin-right: 0;
                        margin-bottom: 40px;
                    }

                    
                }
                .priceText {
                    display: flex;
                    flex-direction: column;
                    text-align: center;
                    align-items: center;
                    padding: 5% 5%;
                    .left {
                        width: 100%;
                    }
                    .right {
                        width: 100%;
                    }
                }
            }
            @media (max-width: #{$tablet-width}) {
                
            }
        }
    }

    .title {
        width: 96%;
        text-align: center;
        margin-bottom: 70px;

        h1 {
            margin-bottom: 10px;
        }

        p {
            font-size: 20px;
            font-weight: 300;
        }
    }

    .prices2 {
        width: 96%;
        text-align: center;
        margin-bottom: 70px;
        background-color: $backgroundGrayDarker;
        .priceText {
            display: flex;
            text-align: left;
            align-items: center;
            padding: 5% 5%;
            .left {
                width: 70%;
            }
            .right {
                width: 30%;
            }
            .aaa {
                color: white;
            }
        }
        h1 {
            margin-top: 40px;
        }
        a {
            color: $blue1;
        }
        .pricesBoxes {
            display: flex;
            text-align: center;
            padding: 5%;
            img {
                width: 36px;
                margin-bottom: 10px;
            }
            .priceBox {
                padding: 24px;
                margin-right: 24px;
                flex: 0.33;
                text-align: left;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;

                .priceBoxPs1 {
                    width: 236px;
                }

                .priceBoxPs2 {
                    width: 130px;
                }

                .priceBoxPs3 {
                    width: 170px;
                }

                p {
                    span {
                        margin-left: 10px;
                    }
                }
                .titlePrice {
                    font-size: 26px;
                    font-weight: 800;
                    margin-bottom: 16px;
                }

                &:last-child {
                    margin-right: 0;
                }
            }

            .blued {
                color: $blue1;
            }
        }

        & {
            @media (max-width: #{$mobile-width}) {
                text-align: center;
                .pricesBoxes {
                    display: block;
                    text-align: center;
                    .priceBox {
                        // flex: 0.5;
                        margin-right: 0;
                        margin-bottom: 10px;
                        text-align: center;
                        display: flex;
                        justify-content: center;
                        flex-direction: column;
                    }

                    
                }
                .priceText {
                    display: flex;
                    flex-direction: column;
                    text-align: center;
                    align-items: center;
                    padding: 5% 5%;
                    .left {
                        width: 100%;
                    }
                    .right {
                        width: 100%;
                    }
                }
            }
            @media (max-width: #{$tablet-width}) {
                
            }
        }
    }

    .priceTitle {
        margin-top: 70px;
    }

    .prices3 {
        width: 96%;
        text-align: center;
        margin-bottom: 70px;
        background-color: $backgroundGray;
        .priceText {
            display: flex;
            text-align: left;
            align-items: center;
            padding: 5% 5%;
            .left {
                width: 70%;
            }
            .right {
                width: 30%;
            }
            .aaa {
                color: white;
            }
        }
        h1 {
            margin-top: 40px;
        }
        a {
            color: $blue1;
        }
        .pricesBoxes {
            display: flex;
            text-align: center;
            padding: 5% 5% 24px 5%;

            .priceBox {
                background-color: $backgroundGray;
                margin-right: 24px;
                flex: 0.33;
                font-size: 22px;

                img {
                    width: 100%;
                    margin-bottom: 10px;
                }

                &:last-child {
                    margin-right: 0;
                }
            }

            .blued {
                color: $blue1;
            }  
        }

        .subtext {
            padding-bottom: 20px;
        } 

        & {
            @media (max-width: #{$mobile-width}) {
                text-align: center;
                .pricesBoxes {
                    display: block;
                    text-align: center;
                    .priceBox {
                        margin-right: 0;
                        margin-bottom: 40px;
                    }

                    
                }
                .priceText {
                    display: flex;
                    flex-direction: column;
                    text-align: center;
                    align-items: center;
                    padding: 5% 5%;
                    .left {
                        width: 100%;
                    }
                    .right {
                        width: 100%;
                    }
                }
            }
            @media (max-width: #{$tablet-width}) {
                
            }
        }
    }

    .contactUs {
        width: 96%;
        // margin-top: 70px;
        background-color: $backgroundGray;
        margin-bottom: 24px;
        padding: 5% 0;
        text-align: center;

        .highlighted {
            font-size: 18px;
        }

        h1 {
            margin-top: 0;
        }

        a {
            color: $blue1;
        }
    }

    .footer {
        font-size: 11px;
        padding-bottom: 5px;
        p {
            text-align: right;
        }
    }

    .lighterBg {
        background-color: $backgroundGray;
    }

    .darkerBg {
        background-color: $backgroundGrayDarker;
        .textBox {
            background-color: $backgroundGrayDarker; 
        }
    }

    .noMarginUp {
        margin-top: 0;
    }
}