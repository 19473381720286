@import '../../../styles/variables.scss';

.linkBtn {
    border: 1px solid black;
    color: black;
    text-decoration: none;
    border-radius: 20px;
    padding: 5px 10px;
    cursor: pointer;
}

.whiteBtn {
    border: 1px solid white;
    color: white;
    text-decoration: none;
    border-radius: 20px;
    padding: 5px 10px;
    cursor: pointer;
}

.purpleGradientBtn {
    border: none;
    color: white;
    text-decoration: none;
    border-radius: 20px;
    padding: 5px 10px;
    cursor: pointer;
    background: -webkit-linear-gradient(-50deg, #7b0eaa,  #fa9ef6,  #7b0eaa);
}

.blueGradientBtn {
    border: none;
    color: white;
    text-decoration: none;
    border-radius: 20px;
    padding: 5px 10px;
    cursor: pointer;
    background: -webkit-linear-gradient(-50deg, $blue1,  $blue1light,  $blue1);
}

#PrimaryButton {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 24px 0;

    .btn {
        background-color: $blue1;
        cursor: pointer;
        padding: 10px 38px;
        color: white;
        font-weight: 600;
        font-size: 18px;
        text-decoration: none;
        border-radius: 40px;
        box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.1);

        &:hover {
            background-color: $blue1_hover;
            transition: 0.5s;
        }
        .aaa {
            color: white;
        }
    }

    &.greyBg {
        background-color: $backgroundGray;
        margin: 0;
        padding-top: 24px;
        padding-bottom: 24px;
    }

    &.moveLeft {
        justify-content: flex-start;
    }

    &.moveRight {
        justify-content: flex-end;
    }
}