@import '../../../styles/variables.scss';

#Home {
    width: 100%;
    margin: 0 auto;

    .intro {
        width: 96%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: 90px;
        margin-top: 140px;
        text-align: center;

        h1 {
            margin-top: 24px;
            margin-bottom: 0;
        }

        .biggerText {
            font-size: 24px;
            margin-bottom: 16px;
        }

        .subtext {
            margin-top: 10px;
        }

        & {
            @media (max-width: #{$mobile-width}) {
                margin-bottom: 70px;
                h1 {
                    margin-top: 20px;
                }
                .biggerText {
                    line-height: 28px;
                }
            }
            @media (max-width: #{$tablet-width}) {
                
            }
        }
    }

    .main {
        width: 96%;
        display: flex;
        margin-bottom: 70px;
        background-color: $backgroundGrayDarker;

        .left {
            width: 60%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            margin-right: 30px;
            margin-left: 24px;
            margin-top: 24px;
            margin-bottom: 24px;
            
            p {
                font-size: 20px;
                line-height: 20px;
                margin-bottom: 20px;
            }
        }

        .right {
            width: 40%;
            margin-right: 24px;
            margin-top: 24px;
            margin-bottom: 24px;

            img {
                width: 100%;
                height: auto;
            }
        }

        & {
            @media (max-width: #{$mobile-width}) {
                background-color: white;
                display: block;
                text-align: center;
                margin-bottom: 40px;
                .left {
                    margin-right: 0;
                    width: 100%;
                    margin-left: 0;
                }
                .right {
                    width: 100%;
                    margin-right: 0;
                    margin-top: 24px;
                    margin-bottom: 0;
                }
                #PrimaryButton.moveLeft {
                    justify-content: center;
                }
            }
            @media (max-width: #{$tablet-width}) {
                .left {
                    h2 {
                        margin-top: 0;
                    }
                }
            }
        }
    }

    .logos {
        padding: 0px 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .logo {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 12%;
            padding: 0 10px;

            img {
                max-width: 100%;
                max-height: 100%;
            }
        }
    }

    .title {
        width: 96%;
        text-align: center;
        margin-bottom: 70px;

        h1 {
            margin-bottom: 10px;
        }

        p {
            font-size: 20px;
            font-weight: 300;
        }
    }

    .configurator {
        width: 96%;
        .configuratorTextBox {
            background-color: $backgroundGrayDarker;
            text-align: center;
            padding: 70px 5%;

            h2 {
                margin-top: 0;
                margin-bottom: 16px;
            }
            p {
                margin-bottom: 10px;

                &:last-child {
                    margin: 0;
                }
            }

            &:last-child {
                margin-bottom: 70px;
            }
        }

        .angles {
            display: flex;
            justify-content: space-between;
            align-items: center;
            background-color: $backgroundGrayVeryDark;
            padding: 10px 0;
            .left {
                display: flex;
                align-items: center;
                .screenshot {
                    cursor: not-allowed;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-right: 20px;
                    margin-left: 24px;
                    cursor: not-allowed;
                    border: 1px solid black;
                    border-radius: 16px;
                    padding: 2px 10px;
                    .screenshotImg {
                        width: 30px;
                        margin: 0;
                        margin-right: 5px;
                        padding: 0;
                    }
                }
                
                img {
                    width: 70px;
                    border-radius: 10px;
                    margin-right: 10px;
                    cursor: pointer;
                    &:first-child {
                        margin-left: 24px;
                    }
                }
                .blureSmall {
                    opacity: 0.3;
                }
                .showSmall {
                    opacity: 1;
                }
            }
            .right {
                p {
                    font-size: 28px;
                    margin-right: 24px;
                    font-weight: 900;
                }
            }

            & {
                @media (max-width: #{$mobile-width}) {
                    flex-direction: column-reverse;
                    justify-content: center;
                    .left {
                        display: flex;
                        justify-content: center;
                        img {
                            width: 16%;
                            &:first-child {
                                margin-left: 10px;
                            }
                            &:last-child {
                                margin-right: 0px;
                            }
                        }
                        .screenshot {
                            display: none;
                        }
                    }
                    .right {
                        p {
                            margin: 0;
                            margin-bottom: 20px;

                        }
                    }
                }
                @media (max-width: #{$tablet-width}) {
                    
                }
            }
        }

        .configuratorMain {
            display: flex;
            background-color: $backgroundGray;
            .left {
                .hidePic {
                    display: none;
                }
                .showPic {
                    display: block;
                }
                display: flex;
                flex-direction: column;
                width: 75%;
                justify-content: center;
                align-items: center;
                .picWrapper {
                    position: relative;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    img {
                        width: 80%;
                    }
                    .picTextUp {
                        position: absolute;
                        top: 30px; 
                        left: calc(50% - 130px);
                    }
                    .picText {
                        position: absolute;
                        bottom: 30px;
                        left: calc(50% - 145px);
                        text-align: center;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        .bordered {
                            cursor: pointer;
                            border-radius: 14px;
                            margin-top: 5px;
                            margin-bottom: 5px;
                            padding: 2px 15px;
                            border: 1px solid black;
                        }
                        img {
                            width: 40px;
                        }
                    }
                }
            }
            .right {
                display: flex;
                flex-direction: column;
                width: 25%;
                display: flex;
                justify-content: center;
                align-items: center;

                .blackBg {
                    background-color: black;
                    width: 90%;
                    margin-top: 40px;
                    margin-bottom: 40px;
                    border-radius: 20px;

                    .mainArea {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        // max-height: 600px;
                        border-radius: 20px;

                        background-image: url('../../../assets/pics/PaulSofa_Cashmere_BG_Blur.png');
                        background-size:cover;
                        background-blend-mode:soft-light;
                        background-repeat: no-repeat;
                        background-position: center;
                        background-color: rgba(0,0,0, 0.25);
                        .boxItem {
                            background-color: white;
                            width: 70%;
                            border-radius: 20px;
                            padding: 10px;
                            margin-bottom: 10px;
                            margin-top: 34px;

                            div {
                                display: flex;
                                justify-content: space-between;

                                &:last-child {
                                    margin-bottom: 20px;
                                }
                            }
                        }

                        .boxItemSelect {
                            background-color: white;
                            width: 70%;
                            border-radius: 20px;
                            padding: 10px;
                            margin-bottom: 10px;
                            // margin-top: 30px;

                            &:last-child {
                                margin-bottom: 34px;
                            }

                            div {
                                p {
                                    &:last-child {
                                        font-size: 12px;
                                    }
                                }
                            }

                            .options {
                                display: flex;
                                margin: 20px 0 0 10px;
                                div {
                                    border-radius: 5px;
                                    width: 40px;
                                    height: 40px;
                                    margin-right: 5px;
                                    cursor: pointer;
                                    &:hover {
                                        opacity: 0.8;
                                    }
                                }
                                .selected {
                                    border: 2px solid rgba(1, 255, 238, 0.824);
                                    width: 36px;
                                    height: 36px;
                                }
                                .Black {
                                    background-color: rgb(38, 38, 38);
                                    background-image: url('../../../assets/pics/Fabric/Black.jpg');
                                    background-repeat: no-repeat;
                                    background-position: center;
                                    background-size: cover;
                                }
                                .Cashmere {
                                    background-color: rgb(221, 202, 179);
                                    background-image: url('../../../assets/pics/Fabric/Cashmere.jpg');
                                    background-repeat: no-repeat;
                                    background-position: center;
                                    background-size: cover;
                                }
                                .BeigeFabric {
                                    background-color: beige;
                                    background-image: url('../../../assets/pics/Fabric/BeigeFabric.jpg');
                                    background-repeat: no-repeat;
                                    background-position: center;
                                    background-size: cover;
                                }
                                .Orange {
                                    background-color: orange;
                                    background-image: url('../../../assets/pics/Fabric/PipingOrange.jpg');
                                    background-repeat: no-repeat;
                                    background-position: center;
                                    background-size: cover;
                                }
                                .VelvetGreen {
                                    background-color: rgb(0, 78, 0);
                                    background-color: beige;
                                    background-image: url('../../../assets/pics/Fabric/VelvetGreen.jpg');
                                    background-repeat: no-repeat;
                                    background-position: center;
                                    background-size: cover;
                                }
                                .Chrome {
                                    background-color: rgb(143, 143, 143);
                                    background-image: url('../../../assets/pics/Fabric/Chrome.jpg');
                                    background-repeat: no-repeat;
                                    background-position: center;
                                    background-size: cover;
                                }
                                .Brass {
                                    background-color: rgb(143, 93, 0);
                                    background-image: url('../../../assets/pics/Fabric/Brass.jpg');
                                    background-repeat: no-repeat;
                                    background-position: center;
                                    background-size: cover;
                                }
                                .Gold {
                                    background-color: rgb(219, 182, 0);
                                    background-image: url('../../../assets/pics/Fabric/Brass.jpg');
                                    background-repeat: no-repeat;
                                    background-position: center;
                                    background-size: cover;
                                }
                            }
                        }

                        
                    }

                    .btnCart {
                        display: flex;
                        justify-content: center;
                        margin: 14px 10px;
                        p {
                            cursor: not-allowed;
                            color: white;
                            margin: 0;
                            padding: 5px 10px;
                            border-radius: 20px;
                            border: 1px solid white;
                        }
                    }
                }
            }

            & {
                @media (max-width: #{$mobile-width}) {
                    flex-direction: column;
                    .left {
                        width: 100%;
                        .picWrapper {
                            img {
                                width: 100%;
                            }
                        }
                    }
                    .right {
                        width: 100%;
                        .blackBg {
                            width: 100%;
                        }
                    }
                }
                @media (max-width: #{$tablet-width}) {
                    
                }
            }
        }
    }

    .offers {
        width: 96%;
        display: flex;
        margin-bottom: 24px;

        .left {
            flex: 0.5;
            padding: 5%;
            background-color: $backgroundGrayDarker;
            margin-right: 24px;
            text-align: center;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;

            .highlighted {
                font-weight: 800;
                font-size: 24px;
            }

            p {
                font-size: 22px;
                line-height: 24px;
                font-weight: 800;
            }

            .boxes {
                display: flex;
                flex-direction: column;
                max-width: 600px;
                .box {
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-content: center;
                    margin-bottom: 12px;
                    height: 80px;

                    &:last-child {
                        margin-bottom: 0;
                    }

                    p {
                        font-size: 20px;
                    }

                    .leftPart {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        margin-right: 24px;
                        flex: 0.1;
                        p {
                            color: rgb(136, 136, 136);
                            font-size: 50px;
                            font-weight: 700;
                            &.pink {
                                color: rgb(200, 200, 200);
                            }
                            &.green {
                                color: rgb(20, 20, 20);;
                            }
                        }
                    }

                    .rightPart {
                        background-color: rgb(136, 136, 136);
                        border-radius: 40px;  
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        padding: 0 40px;
                        flex: 0.9;
                        p {
                            color: white;
                            font-weight: 400;
                        }
                        &.green {
                            background-color: rgb(20, 20, 20);
                        }
                        &.pink {
                            background-color: rgb(200, 200, 200);
                        }
                    }
                }
            }

            .green {
                color: $green1;
            }
        }
        .right {
            flex: 0.5;
            padding: 5%;
            background-color: $backgroundGrayDarker;
            text-align: center;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            font-weight: 800;

            .highlighted {
                font-weight: 800;
                font-size: 24px;
            }

            p {
                margin-bottom: 20px;
                font-size: 22px;
                line-height: 24px;
            }
            .picWrapper {
                width: 100%;
                max-width: 400px;
                display: flex;
                margin: 0 auto;
                img {
                    width: 100%;
                }
            }
            .orange {
                color: $orange1;
            }
        }

        & {
            @media (max-width: #{$mobile-width}) {
                display: block;

                .left {
                    margin-right: 0;
                    margin-bottom: 24px;
                    p {
                        margin-bottom: 24px;
                    }
                    .boxes {
                        margin-top: 24px;
                        .box {
                            // height: 60px;
                            p {
                                margin: 0;
                            }
                        }
                    }
                }
            }
            @media (max-width: #{$tablet-width}) {
                
            }
        }
    }

    .customizable {
        width: 86%;
        display: flex;
        padding: 5%;
        justify-content: space-between;
        align-items: center;
        background-color: $backgroundGrayDarker;
        margin-bottom: 24px;

        .highlighted {
            font-weight: 800;
            font-size: 24px;
        }

        p {
            flex: 0.5;
            text-align: center;
            margin-right: 5%;
            font-size: 22px;
            line-height: 22px;
            font-weight: 800;
        }
        .picWrapper {
            flex: 0.5;
            width: 100%;
            display: flex;
            justify-content: flex-end;
            img {
                width: 100%;
                max-width: 800px;
            }
        }

        & {
            @media (max-width: #{$mobile-width}) {
                display: block;
                p {
                    margin-right: 0;
                    margin-bottom: 24px;
                }
            }
            @media (max-width: #{$tablet-width}) {
                
            }
        }
    }

    .increaseSales {
        width: 96%;
        .textBox {
            background-color: $backgroundGray;
            text-align: center;
            padding: 70px 5%;

            h2 {
                margin-top: 0;
                margin-bottom: 16px;
            }
            p {
                margin-bottom: 10px;

                &:last-child {
                    margin: 0;
                }
            }

            &:last-child {
                margin-bottom: 70px;
            }
        }
        img {
            width: 100%;
        }
    }

    .banner {
        width: 100%;
        background-color: black;
        text-align: center;
        padding: 5% 0;
        margin-bottom: 24px;

        h1 {
            margin: 0;
            margin-bottom: 40px;
            color: white;
        }

        .btn1 {
            margin-bottom: 0;
        }

        & {
            @media (max-width: #{$mobile-width}) {
                h1 {
                    font-size: 40px;
                    line-height: 40px;
                }
                .btn1 {
                    margin-bottom: 24px;
                }
            }
            @media (max-width: #{$tablet-width}) {
                
            }
        }
    }

    .views {
        width: 96%;
        display: flex;
        margin-bottom: 24px;

        .boxes {
            flex: 0.5;
            padding: 5%;
            background-color: $backgroundGray;
            text-align: center;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            margin-right: 24px;

            .textWrapper {
                margin-bottom: 20px;

                .boxTitle {
                    font-weight: 600;
                    font-size: 28px; 
                }

                p {
                    margin-bottom: 10px;
                }

                .big3d {
                    font-family: source-sans-pro-bold;
                    font-size: 24px;
                    // font-weight: 900;
                    margin-bottom: 10px;
                    margin-top: 10px;
                    cursor: pointer;
                }
            }

            .picWrapper {
                width: 100%;
                max-width: 500px;
                display: flex;
                margin: 0 auto;
                img {
                    width: 100%;
                }
            }

            .hidePic {
                display: none;
            }


            .boxFooter {
                background-color: $backgroundGray;
                .icon3d {
                    margin-top: 10px;
                    width: 40px;
                    cursor: pointer;
                }
                .dots {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    img {
                        width: 24px;
                        cursor: pointer;
                        &:first-child {
                            margin-right: 5px;
                        }
                    }
                    .selected {
                        width: 26px;
                    }
                }
            }

            .orange {
                color: $orange1;
            }

            &.boxBlack {
                background-color: black;
                p {
                    color: white;
                }
            }

            &.boxPink {
                background-color: $red1;
                p {
                    color: white;
                }
            }
        }

        // .first360 {
        //     padding-bottom: 0;
        // }

        .noMarginRight {
            margin-right: 0;
        }

        & {
            @media (max-width: #{$mobile-width}) {
                display: block;
                .boxes {
                    margin-right: 0;
                    margin-bottom: 24px;

                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
            @media (max-width: #{$tablet-width}) {
                display: block;
                .boxes {
                    margin-right: 0;
                    margin-bottom: 24px;

                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }

    .whyNeedUsBox {
        width: 96%;
        margin-top: 46px;
        text-align: center;
        margin-bottom: 24px;

        h1 {
            margin-left: 24px;
            margin-right: 24px;
            margin-bottom: 70px;
        }

        .whyNeedBoxes {
            display: flex;
            justify-content: space-between;
            // align-items: stretch;
            background-color: $backgroundGray;

            div#PrimaryButton.greyBg {
                margin-top: 20px;
            }

            div {
                flex: 0.33;
                padding: 5% 8% 5% 8%;
                display: flex;
                justify-content: space-between;
                flex-direction: column;

                p {
                    text-align: center;
    
                    .bolded {
                        font-weight: 800;
                    }
                }
            }

            .div1 {
                flex: 0.33;
                padding: 5% 3% 5% 8%;
                display: flex;
                justify-content: space-between;
                flex-direction: column;
            }

            .div2 {
                flex: 0.33;
                padding: 5% 3% 5% 3%;
                display: flex;
                justify-content: center;
                flex-direction: column;
            }

            .div3 {
                flex: 0.33;
                padding: 5% 8% 5% 3%;
                display: flex;
                justify-content: space-between;
                flex-direction: column;
            }

            .dividerP {
                height: 50px;
            }
        }

        & {
            @media (max-width: #{$mobile-width}) {
                .whyNeedBoxes {
                    display: block;
                    margin-bottom: 24px;
                }
            }
            @media (max-width: #{$tablet-width}) {
                h1 {
                    margin-bottom: 40px;
                }
                .whyNeedBoxes {
                    div {
                        padding: 3% 4% 0 4%;
                        margin-bottom: 24px;

                    }
                }
            }
        }
    }

    .iconsBox {
        width: 96%;
        background-color: black;
        text-align: center;
        margin-bottom: 24px;

        .titleLarge {
            margin-top: 5%;
            line-height: 40px;
            color: white;
            padding-left: 10%;
            padding-right: 10%;
            font-size: 36px;
        }
        .iconBoxes {
            display: flex;
            justify-content: space-between;
            div {
                padding: 40px 24px 5% 24px;
                flex: 0.2;
                i {
                    margin-bottom: 10px;
                    font-size: 24px;
                }
                p {
                    font-weight: 600;
                }
            }
        }
        .greenIcon {
            color: $green1;
            p {
                color: $green1;
            }
        }
        .pinkIcon {
            color: $red1;
            p {
                color: $red1;
            }
        }
        .blueIcon {
            color: $blue1;
            p {
                color: $blue1;
            }
        }
        .orangeIcon {
            color: $orange1;
            p {
                color: $orange1;
            }
        }
        .yellowIcon {
            color: $yellow1;
            p {
                color: $yellow1;
            }
        }

        & {
            @media (max-width: #{$mobile-width}) {
                .titleLarge {
                    font-size: 26px;
                    line-height: 26px;
                }
                .iconBoxes {
                    display: block;
                }
            }
            @media (max-width: #{$tablet-width}) {
                .titleLarge {
                    font-size: 26px;
                    line-height: 26px;
                }
            }
        }
    }

    .iconsBox2 {
        width: 96%;
        background-color: $backgroundGray;
        text-align: center;
        margin-bottom: 24px;

        .iconBoxes2 {
            display: flex;
            justify-content: space-around;
            div {
                padding: 5% 8%;
                flex: 0.33;
                i {
                    margin-bottom: 10px;
                    font-size: 36px;
                }
                p {
                    .bolded {
                        font-weight: 800;
                    }
                }
            }
        }

        & {
            @media (max-width: #{$mobile-width}) {
                .iconBoxes2 {
                    flex-wrap: wrap;
                    div {
                        padding: 3% 4%;
                        i {
                            margin-bottom: 10px;
                            font-size: 26px;
                        }
                    }
                    .xxx {
                        flex: 50%;
                    }
                    .yyy {
                        flex: 50%;
                    }
                }
            }
            @media (max-width: #{$tablet-width}) {
                .iconBoxes2 {
                    justify-content: space-between;
                    div {
                        padding: 3% 5%;
                        flex: 0.33;
                        i {
                            margin-bottom: 10px;
                            font-size: 30px;
                        }
                    }
                }
            }
        }
    }

    .processTitle {
        background-color: black;
        width: 96%;
        text-align: center;
        padding: 5% 0 5% 0;
        color: white;
        margin-bottom: 24px;

        p {
            color: white;
        }
    }

    .processSubitle {
        background-color: black;
        width: 96%;
        text-align: center;
        padding: 5% 0 10px 0;
        color: white;

        p {
            color: white;
            padding: 0 24px;
        }

        .number {
            font-size: 40px;
            font-weight: 700;
            margin-bottom: 16px;
        }

        .biggerText {
            font-size: 28px;
            margin-bottom: 10px;
        }
    }

    .processOne {
        background-color: black;
        width: 96%;
        padding: 5% 0 5% 0;
        display: flex;
        background-image: url('../../../assets/pics/step1-1.png');
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        margin-bottom: 24px;
        display: flex;
        justify-content: center;

        .middle {
            width: 100%;
            display: flex;
            justify-content: center;

            img {
                width: 90%;
            }
        }
    }

    .processTwo {
        background-color: black;
        width: 96%;
        padding: 5% 0 5% 0;
        background-image: url('../../../assets/pics/step2-1.png');
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        margin-bottom: 24px;
        display: flex;
        justify-content: center;

        .middle {
            width: 100%;
            display: flex;
            justify-content: center;

            img {
                width: 90%;
            }
        }
    }

    .processThree {
        background-color: black;
        width: 96%;
        padding: 5% 0 0 0;
        background-image: url('../../../assets/pics/step3-5.png');
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        min-height: 250px;
        position: relative;

        .sideTitle {
            position: absolute;
            margin: 0;
            right: 5%;
            top: 30%;
            color: white;
        }

        & {
            @media (max-width: #{$mobile-width}) {
                min-height: 150px;
                background-size: contain;
                .sideTitle {
                    font-size: 20px;
                    line-height: 20px;
                    top: 32%;
                }
            }
            @media (max-width: #{$tablet-width}) {
                min-height: 150px;
                background-size: cover;
                .sideTitle {
                    font-size: 28px;
                    line-height: 28px;
                    top: 32%;
                }
            }
        }
    }

    .processFooter {
        background-color: black;
        width: 96%;
        text-align: center;
        padding: 5% 0 5% 0;
        color: white;

        p {
            color: white;
        }
    }

    .prices {
        width: 96%;
        text-align: center;
        background-color: $backgroundGray;
        .priceText {
            display: flex;
            text-align: left;
            align-items: center;
            padding: 5% 5%;
            .left {
                width: 70%;
            }
            .right {
                width: 30%;
            }
            .aaa {
                color: white;
            }
        }
        h1 {
            margin-top: 40px;
        }
        a {
            color: $blue1;
        }
        .pricesBoxes {
            display: flex;
            text-align: center;
            padding: 5%;
            .priceBox {
                padding: 24px;
                background-color: white;
                margin-right: 24px;
                flex: 0.33;
                .titlePrice {
                    font-size: 26px;
                    font-weight: 800;
                    margin-bottom: 16px;
                }

                &:last-child {
                    margin-right: 0;
                }
            }

            .blued {
                color: $blue1;
            }
        }

        & {
            @media (max-width: #{$mobile-width}) {
                text-align: center;
                .pricesBoxes {
                    display: block;
                    text-align: center;
                    .priceBox {
                        // flex: 0.5;
                        margin-right: 0;
                        margin-bottom: 40px;
                    }

                    
                }
                .priceText {
                    display: flex;
                    flex-direction: column;
                    text-align: center;
                    align-items: center;
                    padding: 5% 5%;
                    .left {
                        width: 100%;
                    }
                    .right {
                        width: 100%;
                    }
                }
            }
            @media (max-width: #{$tablet-width}) {
                
            }
        }
    }

    .priceTitle {
        margin-top: 70px;
    }

    .contactUs {
        width: 96%;
        margin-top: 70px;
        background-color: $backgroundGray;
        margin-bottom: 24px;
        padding: 5% 0;
        text-align: center;

        .highlighted {
            font-size: 18px;
        }

        h1 {
            margin-top: 0;
        }

        a {
            color: $blue1;
        }
    }

    .footer {
        font-size: 11px;
        padding-bottom: 5px;
        p {
            text-align: right;
        }
    }

    & {
        @media (max-width: #{$tablet-width}) {
            .hideOnTablet {
                display: none;
            }
        }
    }
}