@import './variables.scss';

// THIS IS MAIN CSS FILE!!!

html,
body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: source-sans-pro-regular, sans-serif;
  font-weight: 300;
  font-size: 16px;
  line-height: 20px;
  color: black;
  min-height: 100vh;
}

::selection {
  color: none;
  background: none;
}
/* For Mozilla Firefox */
::-moz-selection {
  color: none;
  background: none;
}

.container {
  width: 100%;
  max-width: 1600px;
  display: flex;
  justify-content: center;
  margin: 0 auto;
}

h1,h2,h3,h4,h5,h6 {
  font-family: source-sans-pro-bold, sans-serif;
}

h1 {
  font-size: 50px;
  line-height: 50px;
  margin-bottom: 24px;
}

h2 {
  font-size: 40px;
  line-height: 40px;
}

a {
  text-decoration: none;
  font-size: 14px;
  cursor: pointer;
}

p {
  color: black;
  line-height: 20px;
  margin: 0;
}

.highlighted {
  font-weight: 800;
}

.btn {
  background-color: $blue1;
  cursor: pointer;
  padding: 10px 20px;
  color: white;
  font-weight: 500;
  border-radius: 40px;
  box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.1);
  margin: 0;

  &:hover {
      background-color: $blue1_hover;
      transition: 0.5s;
  }
}

#root {
  min-height: 100vh;
}

.disabled-link {
  pointer-events: none;
  cursor: not-allowed;
}

.viewerTitle {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  background-color: $backgroundGrayVeryDark;
  display: flex;
  align-items: center;
  height: 60px;
  padding: 0 20px;
  z-index: 2;

  .screenshot {
    cursor: pointer;
    border: 1px solid black;
    border-radius: 20px;
    padding: 4px 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 150px;
    i {
      margin-right: 5px;
    }
  }

  .tit {
    font-size: 24px;
    font-weight: 900;
    display: flex;
    justify-content: center;
  }

  .closeBtn {
    font-size: 26px;
    cursor: pointer;
    display: flex;
    justify-content: flex-end;
  }

  .one {
    width: 33%;
  }
}

.smallTitle {
  position: absolute;
  z-index: 2;
  bottom: 40px;
  right: 50%;
  transform: translate(50%, 0%);
  font-size: 24px;
  font-weight: 600;
  border: 1px solid black;
  display: flex;
  align-items: center;
  padding: 2px 4px;
  border-radius: 20px;
  img {
    width: 24px;
    margin-right: 5px;
  }
  p {
    font-size: 16px;
    font-weight: 100;
  }
}

@font-face {
  font-family: source-sans-pro-regular;
  src: url(../assets/fonts/SourceSansPro-Regular.ttf);
}

@font-face {
  font-family: source-sans-pro-bold;
  src: url(../assets/fonts/SourceSansPro-Bold.ttf);
}

@font-face {
  font-family: source-sans-pro-semi-bold;
  src: url(../assets/fonts/SourceSansPro-SemiBold.ttf);
}

@font-face {
  font-family: source-sans-pro-light;
  src: url(../assets/fonts/SourceSansPro-Light.ttf);
}

@font-face {
  font-family: source-sans-pro-extra-light;
  src: url(../assets/fonts/SourceSansPro-ExtraLight.ttf);
}
