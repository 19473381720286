@import '../../../styles/variables.scss';

#PaulSofa_Cashmere_Black_Chrome {
  z-index: 9;
  box-sizing: border-box;

  img {
    -webkit-user-drag: none;
    -khtml-user-drag: none;
    -moz-user-drag: none;
    -o-user-drag: none;
  }

  .bodyBox {
    background-color: $backgroundGrayDarker;
    z-index: 11;
    width: 100%;
    height: 100%;
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    cursor: grab;

    &.canHover {
      cursor: grabbing;
    }

    .card {
      width: 100%;
      height: 100%;
      max-height: 534px;
      max-width: 800px;
      position: relative;
      display: flex;
      flex-direction: column;
      cursor: grab;

      span {
        position: absolute;
        top: 0;
        left: calc(100% / 30 * var(--i));
        width: calc(100% / 30);
        height: 100%;
      }

      img {
        position: absolute;
        top: 50%;
        transform: translate(0, -50%);
        left: 0;
        width: 100%;
        object-fit: cover;
        opacity: 0;
        pointer-events: none;
      }

      // img:nth-child(var(--i) - 1) { // ovo
      //   opacity: 1;
      // }

      // span:hover + img {
      //   opacity: 1;
      // }

      .show_1 {
        opacity: 1;
      }

      .hide_1 {
        opacity: 0;
      }
    }
    .canHover {
      cursor: grabbing;

      // img:nth-child(var(--i)) {
      //   opacity: 1;
      // }

      // img:nth-child(2) {
      //   opacity: 0;
      // }
      
      // span:hover + img { // ovo
      //   opacity: 1;
      // }
    }
  }

  .canHover {
    cursor: grabbing;
  }

  .jjj {
    cursor: grab;
  }
}