@import '../../../styles/variables.scss';

#NavigationVirtualExperience {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 70px;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #000000;
  width: 100vw;
  position: fixed;
  z-index: 2;

  img {
    width: 100px;
    cursor: pointer;
    margin-left: 40px;
    display: flex;
    align-items: center;
    box-shadow: none;
  }

  .rightSpace {
    width: 150px;
    position: relative;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 150px;
    margin-right: 20px;

    .hamburger {
      display: none;
      width: 30px;
      height: 24px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
  
      .hamburgerItem {
        width: 100%;
        height: 3px;
        background-color: white;
      }

      & {
        @media (max-width: 1500px) {
          display: flex;
        }
      }
      display: none;
    }

    .navList {
      position: absolute;
      top: 35px;
      right: 0;
      display: flex;
      flex-direction: column;
      background-color: white;
      width: 50vw;
      padding: 20px;
      justify-content: center;
      align-items: center;
      box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2);

      span {
        margin-bottom: 20px;
        text-align: center;

        .link {
          text-decoration: none;
          font-size: 18px;
        }

        &:last-child {
          margin-bottom: 0;
        }
        &:first-child {
          margin-bottom: 30px;
          font-size: 12px;
          border: 1px solid black;
          border-radius: 10px;
          padding: 4px 8px;
        }
      }
    }
  }

  .links {
    margin-right: 40px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: white;

    span {
      margin-left: 40px;
      &:first-child {
        margin-left: 0;
      }

      &:hover {
        color: white;
      }

      .link {
        text-decoration: none;

        &:hover {
          transition: 0.5s;
        }
      }

      & {
        @media (max-width: 1500px) {
          display: none;
        }
      }
    }
  }

  @media (min-width: 1500px) {
    .rightSpace {
      display: none;
    }
  }
}