@import '../../../styles/variables.scss';

#RedSofa {
  z-index: 9;
  box-sizing: border-box;

  img {
    -webkit-user-drag: none;
    -khtml-user-drag: none;
    -moz-user-drag: none;
    -o-user-drag: none;
  }

  .bodyBox {
    background-color: $backgroundGray;
    z-index: 11;
    width: 100%;
    height: 100%;
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    cursor: grab;

    &.canHover {
      cursor: grabbing;
    }

    .card {
      width: 100%;
      height: 100%;
      max-height: 534px;
      max-width: 800px;
      position: relative;
      display: flex;
      flex-direction: column;
      cursor: grab;

      span {
        position: absolute;
        top: 0;
        left: calc(100% / 30 * var(--i));
        width: calc(100% / 30);
        height: 100%;
      }

      img {
        position: absolute;
        top: 50%;
        transform: translate(0, -50%);
        left: 0;
        width: 100%;
        object-fit: cover;
        opacity: 0;
        pointer-events: none;
      }

      // img:nth-child(var(--i) - 1) { // ovo
      //   opacity: 1;
      // }

      // span:hover + img {
      //   opacity: 1;
      // }

      .show_1 {
        opacity: 1;
      }

      .hide_1 {
        opacity: 0;
      }
    }
    .canHover {
      cursor: grabbing;

      // img:nth-child(var(--i)) {
      //   opacity: 1;
      // }

      // img:nth-child(2) {
      //   opacity: 0;
      // }
      
      // span:hover + img { // ovo
      //   opacity: 1;
      // }
    }
  }

  .canHover {
    cursor: grabbing;
  }

  .jjj {
    cursor: grab;
  }

  .left {
    display: flex;
    flex-direction: column;
    width: 20%;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 80px;
    left: 0;
    z-index: 20;

    .whiteBg {
      width: 90%;
      border-radius: 20px;
      position: relative;

      .close {
        position: absolute;
        top: 5px;
        left: 10px;
        cursor: pointer;
      }

      .main {
        background-color: $backgroundGrayVeryDark;
        padding-top: 20px;
        padding-bottom: 20px;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 20px;
  
        div {
          width: 90%;
          background-color: white;
          border-radius: 20px;

          .title {
            margin: 5px 5px 10px 5px;
          }

          .text {
            font-size: 12px;
            margin: 0 10px;
          }

          img {
            width: 90%;
          }
        }
      }
    }
  }
    
  .right {
      display: flex;
      flex-direction: column;
      width: 20%;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: 80px;
      right: 0;
      z-index: 20;

      .blackBg {
          background-color: black;
          width: 90%;
          border-radius: 20px;

          .mainArea {
              max-height: 80vh;
              overflow: auto;
              border-radius: 20px;

              background-image: url('../../../assets/pics/PaulSofa_Cashmere_BG_Blur.png');
              background-size:cover;
              background-blend-mode:soft-light;
              background-repeat: no-repeat;
              background-position: center;
              background-color: rgba(0,0,0, 0.25);

              .summary {
                padding: 10px;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: white;
                width: 80%;
                border-radius: 20px;
                margin: 20px auto 10px auto;
              }

              .boxItem {
                  background-color: white;
                  width: 80%;
                  border-radius: 20px;
                  padding: 10px;
                  margin: 0 auto 10px auto;

                  .title {
                    font-size: 12px;
                  }

                  div {
                      display: flex;
                      justify-content: space-between;

                      &:last-child {
                          margin-bottom: 20px;
                      }
                  }
              }

              .picItem {
                background-color: white;
                width: 80%;
                border-radius: 20px;
                padding: 10px;
                margin: 0 auto 10px auto;

                img {
                  width: 100%;
                }
            }
              .specItem {
                background-color: white;
                width: 80%;
                border-radius: 20px;
                padding: 10px;
                margin: 0 auto 10px auto;

                .title {
                  margin-bottom: 10px;
                }

                .specItemInner {
                  margin-bottom: 5px;
                  .val {
                    font-size: 12px;
                  }
                }
              }
            }

            .btnCart {
                display: flex;
                justify-content: center;
                margin: 14px 10px;
                p {
                    cursor: not-allowed;
                    color: white;
                    margin: 0;
                    padding: 5px 10px;
                    border-radius: 20px;
                    border: 1px solid white;
                }
            }
        }
    }
}